import { type TaskStatus } from '@clover/tasks/models/task';
import { type CompanySearchModel } from '@core/models/company';
import { type EnumerationModel } from '@core/models/enumerationModel';
import { type UserInfo } from '@core/models/user';

export interface Campaign {
  id?: number;
  name?: string;
  description?: string;
  audience?: {
    assignments?: CampaignAudienceTask[];
  };
  status?: CampaignStatus;
  workflowId?: number;
  workflowName?: string;
  workflowSnapshotId?: string;
  createdAt?: string;
  publishedAt?: string;
  completionRate?: number;
}

export interface CampaignSearchResult {
  id: number;
  name: string;
  status: CampaignStatus;
  createdAt: string;
  publishedAt: string;
  completionRate: number;
  audienceCompanyListMembersCount: number;
}

export interface CampaignPublishedAudience {
  id?: number;
  acceptedByUser?: UserInfo;
  assignedToCompany?: {
    id: number;
    name: string;
  };
  assignedTo?: {
    communicationRoles?: EnumerationModel[];
    users?: UserInfo;
  };
  assignedToCompanyHeadquartersAddress?: {
    state: string;
    city: string;
  };
  completion?: number;
  status?: TaskStatus;
}

export interface CampaignAudienceTask {
  companyHeadquartersAddress?: {
    state: string;
    city: string;
  };
  company: CompanySearchModel;
  communicationRoles?: EnumerationModel[];
  userContacts?: Array<{
    firstName?: string;
    lastName?: string;
    title?: string;
    userId: number;
    logoUrl?: string;
    id?: number;
  }>;
  taskId?: number;
  status?: TaskStatus;
}

export enum CampaignStatus {
  Draft = 'Draft',
  InProgress = 'InProgress',
  Complete = 'Completed',
  Closed = 'Closed',
}
